import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Register from '../components/Registration/Register';
var qs = require('qs');

const RegisterPage = ({ location }: { location: any }, { data }: any) => {
  const params = new URLSearchParams(location.search);
  const code = params.get('recommender_code');
  // const code=qs.parse(location.search, { ignoreQueryPrefix: true }).recommender_code;
  return (
    <Layout>
      <SEO title="Registrate" />
      <Register recommenderCode={code} />
    </Layout>
  );
};

export default RegisterPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
