import React, { useState } from 'react';
import { useFormik, useFormikContext } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useStyles from './Register.styles';
import { Container, Grid, Typography, Hidden } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'gatsby';
import APIProspect from '../../services/APIProspect.service';
import TransitionAlert from '../Common/Alert';
import { navigate } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Helmet } from 'react-helmet';
import NavBarV2 from '../Header/NavBarV2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Terms from '../TermsAndConditions/Terms';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';

const publicIp = require('react-public-ip');
const validationSchema = yup.object({
  email: yup.string().email('Ingresa un correo valido').required('Correo es requerido'),
  emailConfirmation: yup.string().oneOf([yup.ref('email'), null], 'No coinciden los correos.'),
  password: yup
    .string()
    .required('Contraseña es requerida')
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/,
      'Contraseña debe tener mínimo 8 caracteres y al menos 1 letra y 1 número.'
    ),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Las contraseñas son distintas.'),
  reason: yup.array().min(1).required('Debe escoger una razón para utilizar CurrencyBird'),
  terms: yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones.'),
});

const MenuProps = {
  PaperProps: {
    style: {
      height: `auto`,
      width: 400,
    },
  },
};

const CustomColorCheckbox = withStyles({
  root: {
    padding: '3px',
    '&$checked': {
      color: '#074F71',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const reasonOptions = [
  'Pago proveedor o servicio',
  'Pago estudios en el extranjero',
  'Ayuda familiar',
  'Inversiones y ahorros',
  'Pago de remuneraciones o sueldos',
];

const SignUp = ({ code }: { code: any }) => {
  const classes = useStyles();
  const [submit, setSubmit] = React.useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [reason, setReason] = React.useState([]);

  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
  const [terms, setTerms] = useState(false);

  const openTermsDialog = () => {
    setTermsDialogOpen(true);
  };

  const closeTermsDialog = () => {
    setTermsDialogOpen(false);
  };

  const openPrivacyDialog = () => {
    setPrivacyDialogOpen(true);
  };

  const closePrivacyDialog = () => {
    setPrivacyDialogOpen(false);
  };

  const handleCheckboxChange = () => {
    setTerms(!terms);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      reason: [],
      recommender_code: code,
      terms: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmit(true);
      const userIp = (await publicIp.v4()) || null;
      await APIProspect.post('signup', { ...values, userIp, terms: true })
        .then((response) => {
          if (response) {
            if (typeof window !== `undefined`) {
              navigate('/signup/confirmation', {
                state: { values },
              });
            }
          } else {
            setSubmit(false);
            setAlertOpen(true);
            setAlertTitle('Error al ingresar este correo electrónico.');
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmit(false);
          setAlertOpen(true);
          setAlertTitle('Error al ingresar este correo electrónico.');
        });
    },
  });

  return (
    <section className={`${classes.root} ${classes.section}`}>
      <NavBarV2 />
      <Dialog
        open={termsDialogOpen}
        onClose={closeTermsDialog}
        aria-labelledby="terms-dialog-title"
        className={classes.dialog}
        BackdropComponent={Backdrop}
        TransitionComponent={Fade}
        fullWidth
        maxWidth="lg"
        style={{ margin: '0px !important' }}
        classes={{ paperFullWidth: classes.dialogContent }}
      >
        <DialogActions className={classes.dialogActions}>
          <Box display="flex" alignItems="center" onClick={closeTermsDialog}>
            <IconButton edge="start" color="primary" aria-label="Volver">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="button" color="primary">
              Volver
            </Typography>
          </Box>
        </DialogActions>
        <DialogContent>
          <DialogContentText>
            <Terms />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Helmet bodyAttributes={{ class: 'registerPage' }} />
      <Container className={classes.container} maxWidth={false}>
        <Hidden smDown>
          <Grid item md={6} className={classes.feather} />
        </Hidden>

        <Grid className={classes.formGroup} item xs={12} md={6} lg={5}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
              <Typography component="h1" variant="h5">
                Comienza tu registro
              </Typography>
              <Grid className={classes.subtitle}>
                <p>¿Ya estás registrado? </p>{' '}
                <Link to={`${process.env.GATSBY_URL_NEW_FRONT}/login`}>
                  {' '}
                  <p>
                    <b> Inicia sesión</b>
                  </p>
                </Link>
              </Grid>
            </Grid>
            <Container>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={10} md={9} lg={9}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Correo electrónico"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={10} md={9} lg={9}>
                  <TextField
                    fullWidth
                    id="emailConfirmation"
                    name="emailConfirmation"
                    label="Repetir correo electrónico"
                    value={formik.values.emailConfirmation}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={
                      formik.touched.emailConfirmation && Boolean(formik.errors.emailConfirmation)
                    }
                    helperText={formik.touched.emailConfirmation && formik.errors.emailConfirmation}
                  />
                </Grid>
                <Grid item xs={10} md={9} lg={9}>
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Crear contraseña"
                    type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                    value={formik.values.password}
                    variant="outlined"
                    onChange={formik.handleChange}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex="-1"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                  <FormHelperText>
                    Mínimo 8 caracteres y debe incluir al menos 1 letra y 1 número.
                  </FormHelperText>
                </Grid>
                <Grid item xs={10} md={9} lg={9}>
                  <TextField
                    fullWidth
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    label="Repetir contraseña"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.passwordConfirmation}
                    variant="outlined"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.passwordConfirmation &&
                      Boolean(formik.errors.passwordConfirmation)
                    }
                    helperText={
                      formik.touched.passwordConfirmation && formik.errors.passwordConfirmation
                    }
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex="-1"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={10} md={9} lg={9}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Motivo para utilizar CurrencyBird
                    </InputLabel>
                    <Select
                      labelId="selectReasonLabel"
                      multiple
                      name="reason"
                      value={formik.values.reason}
                      label="Motivo para utilizar CurrencyBird"
                      onChange={formik.handleChange}
                      renderValue={(reason) => reason.join(', ')}
                      MenuProps={MenuProps}
                      inputProps={{
                        name: 'reason',
                        id: 'input-checkbox',
                      }}
                    >
                      {reasonOptions.map((option) => (
                        <MenuItem id="menuItem" key={option} value={option}>
                          <ListItemIcon>
                            <CustomColorCheckbox
                              checked={formik.values.reason.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className={classes.helperText}>
                      {formik.touched.reason && formik.errors.reason
                        ? 'Seleccionar una razón'
                        : null}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={10} md={9} lg={9}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.terms}
                        onChange={formik.handleChange}
                        name="terms"
                        color="primary"
                      />
                    }
                    label=""
                    style={{ pointerEvents: 'auto', marginRight: '8px' }}
                  />
                  Acepto los{' '}
                  <span
                    onClick={openTermsDialog}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Términos y condiciones
                  </span>{' '}
                  y la{' '}
                  <span>
                    <a
                      href="https://cbfrontpublic.s3.amazonaws.com/Poli%CC%81tica+de+Privacidad+y+Proteccio%CC%81n+de+Datos+CurrencyBird.pdf"
                      target="_blank"
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      Política de privacidad
                    </a>
                  </span>
                  <FormHelperText className={classes.helperText} style={{ marginTop: '12px' }}>
                    {formik.touched.terms && formik.errors.terms ? formik.errors.terms : null}
                  </FormHelperText>
                </Grid>

                <Grid className={classes.submitGroup} item xs={10} md={9} lg={9}>
                  <Button
                    fullWidth
                    startIcon={submit ? <CircularProgress size="1rem" /> : null}
                    disabled={submit}
                    color="primary"
                    variant="contained"
                    type="submit"
                    endIcon={<LockOutlinedIcon />}
                  >
                    Continuar
                  </Button>
                </Grid>
                <Grid item xs={10} md={9} lg={9}>
                  {alertOpen && (
                    <TransitionAlert
                      setAlertOpen={setAlertOpen}
                      open={alertOpen}
                      title={alertTitle}
                      description="Por favor, intenta nuevamente."
                      severity="error"
                    />
                  )}
                </Grid>
              </Grid>
            </Container>
          </form>
        </Grid>
      </Container>
    </section>
  );
};

export default SignUp;
